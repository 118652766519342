import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
import {fonts} from "../styles/variables";
import ContactForm from "../components/ContactForm";
import Toggler from "../components/Toggler";
import Voucher from '../components/Voucher'


const P = styled.p`

font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
padding-bottom: 2.5em;
line-height: 1.5em;`


const Pfx = styled.p`

font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
line-height: 1.5em;`

const H2 = styled.h2`


font-size:1.8rem;
margin-bottom:1.5rem;


`

const SmallH2 = styled.h2`
font-size:1.2rem;
margin-bottom:2rem;


`

const Highlight = styled.div`
padding-top:25px;
padding-bottom:25px;
padding-left:9px;
padding-right:9px;
background:rgba(68,68,68,.02);
display:flex;
align-items:center;

`

const Div=styled.div`
color:var(--textNormal);

    @media screen and (max-width: 870px) {
text-align:center; }
`

const PhilosophiePage = () => (
  <IndexLayout>
    <Page>
      <Container   style={{
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}>
        <Div>
        <H2>Herzlich Willkommen im</H2>

        </Div>
        <Div>

        <h1>Vilhelm7 Boutique Coworking</h1>
        </Div>
        <div style={{
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}>
        <br/>
        <br/>
        <h2>Exklusives Arbeiten in historischem Ambiente.</h2>
        <P>Wir sind V7 – der exklusive, liebevolle Boutique Coworking Space, im Zentrum Berlins.
          Spezialisiert auf kleine Teams, bieten wir unseren Gästen ein exklusives Arbeitsumfeld.</P>

        <h2>Fühlen Sie sich ganz einfach wie zu Hause</h2>
        <P>Lassen Sie sich von unseren Private Offices überraschen: das Ambiente der Extraklasse wird Sie begeistern –
          hochwertiges Design in allen Räumlichkeiten kombiniert mit liebevollen Details.</P>


        <h2>Warum Boutique?</h2>
        <P>Boutique steht für Stil, Individualität und Design. Zeitgemäße Arbeitsplätze mit Blick fürs Detail, umgeben von hochwertigem Berliner Altbaucharme. Eingerichtet mit ausgesuchtem Level-Up-Design bieten wir ein umfassendes Komfortgefühl, was unser feines Business-Hub auch zu einem idealen Ort für Meetings,
          Workshops oder kleinere Seminare macht.</P>

        <h2>Bürolösungen neu denken!</h2>
        <P>Um unsere Gäste vor möglichen Infektionen zu schützen, sind unsere jeweiligen Private Offices separat zugänglich und vorrangig für Einzelpersonen oder kleine Teams von 1-6 Personen ausgelegt. Jedes Private Office verfügt über einen privaten Hygienebereich mit eigenem Bad und WC,
          kombiniert mit einem regelmäßigen Desinfektionsservice* der gesamten Räumlichkeiten.</P>

        <h2>So privat als nötig. So vernetzend wie möglich.</h2>
        <P>Separate, flexible Private Offices sind die Bürokultur der Zukunft. Wir ermöglichen kleinen Teams ungestörtes, konzentriertes Arbeiten, losgelöst vom Trubel überfüllter Großraumbüros und bieten gleichzeitig die Möglichkeit, sich in unseren Community Areas auszutauschen und Kontakte zu knüpfen. Denn mit unserem nachhaltigen Hybrid Thinking Konzept verfolgen wir nur ein Ziel – Ihres.
          Unsere Private Offices sind flexibel und genau auf Ihre Bedürfnisse zugeschnitten.
          Nennen Sie uns Ihre Vorlieben und vereinbaren Sie einen Termin mit uns.</P>


        <h2>Profitieren sie von:</h2>
        <ul>
          <li>Diskreten 17-25 qm Offices in privater Umgebung</li>
          <li>Ruhiger Atmosphäre, kein Großraumbüro</li>
          <li>Eigenem Hygienebereich (Bad und WC)</li>
          <li>Private Duschen</li>
          <li>Desinfektionsservice</li>
          <li>Community Areas</li>
          <li>Separatem Konferenzraum</li>
          <li>Übernachtungsmöglichkeiten</li>
          <li>Frühstücksservice</li>
          <li>Kurierdienst</li>
          <li>Optimaler Anbindung</li>
          <li>Kaffee & Tee Bar</li>

        </ul>
<br/>
        <h2>Auf einen Kaffee oder Tee?</h2>

        <P>Sie möchten uns gerne persönlich besuchen und sich vor Ort ein Bild machen?
          Wir rufen gerne zurück und stimmen einen persönlichen Besichtigungstermin mit Ihnen ab. </P>
        <br/>
        <ContactForm/>

<br/>
<br/>
        <SmallH2>
          Sie haben noch Fragen? Wir helfen Ihnen gerne persönlich weiter! <br/>
        </SmallH2>

        <Highlight>
        <Pfx> *In unserem Vilhelm7 Boutique Coworking Space gelten grundsätzlich
          <b> höchste Hygienestandards</b>. Nachdem die Verbreitung des Coronavirus auch in
          Deutschland zu neuen Herausforderungen geführt hat, haben wir unsere Hygienemaßnahmen nochmals intensiviert.
          Unsere Private Offices sind optimal aufgestellt,
          um Ihnen ein <b>sicheres und produktives Arbeiten</b> zu ermöglichen.</Pfx>
        </Highlight>



        <div></div>
        </div>
      </Container>
    </Page>
  </IndexLayout>
)

export default PhilosophiePage
