import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons';
import FloatingLabelInput from 'react-floating-label-input';
import Textarea from 'react-textarea-autosize';
import {Location, navigate} from "@reach/router";
import {fonts} from "../styles/variables";
let moment = require('moment');
import 'react-dates/initialize';
import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';
import Ripples from 'react-ripples'
import 'react-dates/lib/css/_datepicker.css';
moment.locale('de');

const InputDiv = styled.div`
  margin-bottom:1.7em;
font-size:1.4em;

`
const StyledInput = styled(FloatingLabelInput)`

div{
border-bottom: 1px solid var(--inputBg) !important;
}

width: 360px;
font-family:${fonts.chapaProLight};

margin-top:10px;
transition: all .2s ease;

color:rgb(68,68,68,1);


label{
left:7px;



}

input{

background:var(--inputBg);
color:var(--input);
width:100%;
padding-left:7px;
padding-top:4px;
padding-bottom:2px;
border-bottom-color:
rgba(255,255,255,0) !important;

}

:focus{
border-bottom-color:var(--inputBg);
background: rgba(233, 231, 175,.2);



}


 @media screen and (max-width: 400px) {
   width:320px;

  }

`

const StyledForm = styled.form`

`

const FooterBox = styled.div`
 width:auto;
 text-align:left;
  height:120px;
 margin-right:4%;
 padding-right:4%;
 border-right:solid 1px rgba(68, 68, 68, .1);
padding-top:8px;

  @media screen and (max-width: 1295px) {
display:none;
   }

`




const Submit = styled.button`
width: 360px;
padding: 0.5em 1em;
cursor:pointer;

display: block;
font-family: ${fonts.chapaProSub};
font-weight: normal;
font-size: 1.2em;
letter-spacing: 0.05em;
border: none;
outline: none;
background-color: #ffe799;

 @media screen and (max-width: 400px) {
   width:auto;

  }
`




const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name:'',
      phone:''
    }
    this.handleSubmit=this.handleSubmit.bind(this)
  this.handleNameChange=this.handleNameChange.bind(this)
  this.handlePhoneChange=this.handlePhoneChange.bind(this)
  }

  setDates(startDate, endDate){
    this.setState({
      startDate:startDate,
      endDate:endDate
    })

  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "callback", ...this.state })
    })
      .then(() => navigate('/success'))
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleNameChange = e => this.setState({ name: e.target.value });
  handlePhoneChange = e => this.setState({ phone: e.target.value });



  componentDidMount(){
this.setState({
  name:'',
  phone:''
})
  }



  render() {
    return(

      <StyledForm onSubmit={this.handleSubmit} name="callback" method="POST" action="/success" data-netlify="true" >
        <input type="hidden" name="form-name" value="callback"/>

        <InputDiv><p><StyledInput value={this.state.name} label={'Name'}  onChange={this.handleNameChange} name="name" type="text" required /></p></InputDiv>

        <InputDiv> <p>  <StyledInput value={this.state.phone}  onChange={this.handlePhoneChange} label={'Telefon'}  name="phone" type="text" required /></p></InputDiv>
   <br/>
        <Ripples>
          <Submit  type="submit">Rückruf anfordern</Submit>
        </Ripples>

      </StyledForm>



    )
  }
}


export default ContactForm
